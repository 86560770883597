import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "../Services/services.module.css";
import { useNavigate } from "react-router-dom";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";
import { BiFilter, BiSearch } from "react-icons/bi";
import { getAllAssignleads_api } from "../api/service";
import { Pagination } from "@mui/material";
import { getVendor_api } from "../api/vendor";


const AssignleadTable = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [assignLeads , setAssignLeads] = useState()
  const [pageCount, setpageCount] = useState(1);
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("")
  const [vendor , setVendor] = useState()
  const [vendorId , setVendorId] = useState()

  useEffect(()=>{
    fetchallAssignLeads()
  },[pageCount, search , vendorId])


  async function fetchallAssignLeads(data) {
    setisLoading(true);
    try {
        let temp;
        if (data == "reset") {
          setsearch("");
          setStartDate("");
          setendDate("");
          setVendorId("")
          temp = {
            page: pageCount,
            limit: 10,
            search: "",
            startDate: "",
            endDate: "",
            vendorId:""
          };
        } else {
          temp = {
            page: pageCount,
            limit: 10,
            search: search.trim(),
            startDate: startDate,
            endDate: endDate,
            vendorId:vendorId?vendorId:""
          };
        }
      let res = await getAllAssignleads_api(temp);
      if (res.data.status) {
        setpageLength(res?.data?.totalPage);
        setAssignLeads(res.data.data);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchallVendorFunc = async (data) => {
    setisLoading(true);
    try {
       let temp = {
            page: pageCount,
            limit: 100,
            search: "",
            startDate: "",
            endDate: "",
           location:'',
           package:'',
           verify:''
          };
        
        let res = await getVendor_api(temp);
        if (res.data.status) {
            setVendor(res.data.data.vendors);
        } else {
            setisLoading(false);
            console.log("status false!");
        }
    } catch (error) {
        console.log(error);
    }
};

useEffect(()=>{
        fetchallVendorFunc()
},[])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));



  return (
    <div className="">
      <div className={s["article-list-title"]}>
        <h3>Assign Leads Customer</h3>
      </div>
      <div
              className={s["search-box"]}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search users by name..."
              />
            </div>
        <div className="d-flex">
        <div className="search-123" >
            <select className="search-inp1" style={{width:"150px"}} value={vendorId} onChange={(e) => setVendorId(e.target.value)}>
              <option value="">Vendor List</option>
              {
                vendor?.map((elem, id) => {
                  return (
                    <option key={id} value={elem._id}>{elem?.userName}</option>

                  )
                })
              }
            </select>
          </div>
        <div className="search-123">
            <div style={{ display: "flex" }}>
              <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="search-inp1" />
              <input type="date" value={endDate} onChange={(e) => setendDate(e.target.value)} className="search-inp1" />
              <button className="search-inp1" onClick={() => fetchallAssignLeads()}>Apply</button>
              <button className="search-inp1" style={{ border: "1px solid #fff" }} onClick={() => fetchallAssignLeads("reset")}>Reset Filter</button>
            </div>
          </div>
        </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Id</StyledTableCell>
              <StyledTableCell align="center">Lead Name</StyledTableCell>

              <StyledTableCell align="center">Assign vendor</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {assignLeads?.map((row,id) => (
              <StyledTableRow key={row._id}>
                <StyledTableCell align="center">{id+1}</StyledTableCell>
                <StyledTableCell>{row?.customer?.name} </StyledTableCell>
                <StyledTableCell>{row?.vendor?.userName} </StyledTableCell>
                {/* <StyledTableCell align="center">
                  <CiEdit
                    onClick={() =>
                      navigate("/add-services", {
                        state: {
                          pagetype: "Edit",
                          data: row,
                        },
                      })
                    }
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                  
                </StyledTableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {assignLeads?.length <= 0 && <DataNotFound />}
        {assignLeads?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
          </div>
        )}
      </div>
      <Loder loading={isLoading} />
    </div >
  );
};

export default AssignleadTable;
